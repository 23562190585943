import { Meta } from '@solidjs/meta';
import { NotFoundContent } from '../../partials/404';
import { getContentSecurityPolicy } from '../../modules/content-security-policy';

export default function NotFound() {
	return (
		<>
			<Meta http-equiv="content-security-policy" content={getContentSecurityPolicy()} />
			<NotFoundContent />
		</>
	);
}
